import HomeApp from 'apps/Home'
import { getCommonStaticProps } from 'commonPage'
import commonMessages from 'locales/es/common'
import commonEnMessages from 'locales/en/common'
import homeMessages from 'locales/es/home'
import homeEnMessages from 'locales/en/home'

export default HomeApp

export const getStaticProps = async ({ locale }) => {
  const commonStaticProps = await getCommonStaticProps(locale)
  const messages = locale === 'es'
    ? { ...commonMessages, ...homeMessages }
    : { ...commonEnMessages, ...homeEnMessages }
  return {
    props: {
      titleMeta: 'HOME_TITLE',
      descriptionMeta: 'HOME_DESCRIPTION',
      headerProps: {
        activeIndex: 0,
        className: 'bg-gray',
      },
      messages,
      ...commonStaticProps,
    },
  }
}
