import React, { memo } from 'react'
import Image from 'next/image'
import { FormattedMessage } from 'react-intl'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import MainBannerImg from 'public/img/main-banner.webp'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { scrollToElement } from 'utils/universal'
import sx from './styles'

const MainBanner = () => {
  const buttons = [
    {
      text: 'HOME_BUTTON_1_TEXT',
      variant: 'hightlight',
      onClick: () => scrollToElement('categorias'),
    },
    {
      text: 'HOME_BUTTON_2_TEXT',
      variant: 'outlined',
      color: 'secondary',
      onClick: () => scrollToElement('Mapa'),
    },
  ]

  const buttonsComponent = (
    <Box sx={sx.buttonsContainer}>
      {buttons?.length > 0 && buttons?.map((button) => (
        <Button
          key={`button-${button?.text}`}
          variant={button?.variant}
          color={button?.color}
          sx={sx.button}
          onClick={typeof button?.onClick === 'function' ? button?.onClick : null}
        >
          <FormattedMessage id={button?.text} />
        </Button>
      ))}

    </Box>
  )

  return (
    <Container
      maxWidth={false}
      sx={sx.root}
    >
      <Grid
        container
        justifyContent="center"
      >

        <Grid
          item
          xs={12}
          lg={5}
          xl={4}
          sx={{ zIndex: 999 }}
        >
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              sx={sx.badget}

            >
              <Typography variant="caption">
                <FormattedMessage id="STORIES_MEXICO" />
              </Typography>
            </Button>
          </Box>
          <Box>
            <Typography
              variant="h1"
              sx={sx.title}
            >
              <FormattedMessage
                id="HOME_DISCOVER_MEXICO_H1"
              />
              {' '}
              <Typography
                variant="span"
              >
                <FormattedMessage id="HOME_DISCOVER_MEXICO_H1_2" />
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              sx={sx.description}
              my={2}
            >
              <FormattedMessage id="HOME_DISCOVER_MEXICO_DESCRIPTION" />
            </Typography>
          </Box>

          {buttonsComponent}

        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={7}
          sx={{ ...sx.imageContainer, zIndex: 999 }}
        >
          <Image
            src={MainBannerImg}
            layout="responsive"
            priority
            style={{ zIndex: 11 }}
            alt="imagen principal"
            title="imagen principal"
          />
        </Grid>

        {/* ELEMENTS */}
        <Box sx={sx.lineBg} />
        <Box
          sx={sx.scrollBottom}
          onClick={() => scrollToElement('categorias')}
        >
          <ArrowDownwardIcon />
        </Box>

      </Grid>
    </Container>

  )
}

export default memo(MainBanner)
