const styles = {
  root: {
    position: 'relative',
    pt: { xs: 4, lg: 10 },
    px: 2,
    pb: 10,
    background: 'linear-gradient(86.18deg, #F1F1F1 0.82%, rgba(248, 248, 248, 0) 97.15%)',
  },
  title: {
    '& span': {
      color: 'primary.main',
    },
  },
  description: {
    '&.MuiTypography-body1': {
      lineHeight: '22px',
    },
  },
  buttonsContainer: {
    marginTop: 4,
  },
  button: {
    minWidth: { xs: '46%', sm: '200px' },
    '&:first-of-type': {
      marginRight: { xs: 1, sm: 2 },
    },
  },
  badget: {
    boxShadow: '0px 4px 20px 0px #0000001A',
    borderRadius: '50px!important',
    background: 'background.white',
  },

  lineBg: {
    position: 'absolute',
    bottom: 0,
    backgroundImage: 'url(./img/rectangle_bg.webp)',
    left: 0,
    right: 0,
    height: {
      xs: 80, sm: 100, lg: 150, xl: 200,
    },
    zIndex: 2,
    width: '100%',
    backgroundSize: 'cover',
  },
  imageContainer: {
    marginTop: { xs: 2, lg: 'unset' },
  },

  scrollBottom: {
    position: 'absolute',
    cursor: 'pointer',
    bottom: { xs: 0, lg: 50 },
    zIndex: 99,
    width: 45,
    height: 55,
    border: 1,
    borderColor: 'highlight.main',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default styles
