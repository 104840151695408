import React, { memo } from 'react'
import dynamic from 'next/dynamic'
import Page from 'components/Page'
import Categories from 'components/Categories'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import useActivities from 'hooks/useActivities'
import { useRouter } from 'next/router'
import { cleanFilters } from 'filters'
import { useMediaQuery } from '@mui/material'
import { toggleValueFromString } from 'utils/universal'
import { setFilters } from 'redux/modules/map'
import { useDispatch, useSelector } from 'react-redux'
import MainBanner from './MainBanner'
import sx from './styles'

const MapDynamic = dynamic(
  () => import('components/Map'),
  {
    loading: () => (
      <Skeleton
        variant="rectangular"
        animation="wave"
        height="100vh"
        width="100%"
      />
    ),
    ssr: false,
  },
)

const Home = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { filters } = useSelector((s) => s.map)

  const params = {
    city__state__slug__in: router?.query?.estado,
    category__slug__in: router?.query?.categoria,
  }

  const activitiesQuery = useActivities(cleanFilters(params))

  const handleClickCategory = (category) => {
    const newCategoryValue = toggleValueFromString(filters?.category, `${category}`)
    dispatch(setFilters({ category: newCategoryValue }))
  }

  return (
    <Page>

      <MainBanner />

      <Categories
        title="DISCOVER_THE_BEST_STORIES"
        withBorder={!isMobile}
        variant="home"
        onClickCategory={handleClickCategory}
      />

      <Box sx={sx.mapContainer}>
        <MapDynamic
          items={activitiesQuery?.data}
          variant="home"
        />
      </Box>

    </Page>
  )
}

export default memo(Home)
